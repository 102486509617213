import React, { useRef } from 'react';
import NoOffersImage from '/public/no-offers.svg';
import StickyBottom from '@/app/(offerwall)/components/StickyBottom';
import Button from '@/components/ui/Button';

export default function NoFilterOffers({ onReset }) {
  const scrollableRef = useRef(null);
  return (
    <div
      ref={scrollableRef}
      className="px-6 py-6 flex flex-col items-center justify-center h-[calc(100vh_-_200px)] gap-3 landscape:h-[calc(100vh_-_60px)] landscape:w-screen"
    >
      <NoOffersImage className="text-[150px]" />
      <h1 className="text-xl font-bold text-center">
        Looks like there are no offers with the filters selected
      </h1>
      <p className="text-sm text-center text-black/40">Try changing filters.</p>
      <Button
        color="primary"
        variant="outlined"
        className="w-48 py-2"
        onClick={onReset}
      >
        Reset Filters
      </Button>
      <StickyBottom scrollContainer={scrollableRef?.current} />
    </div>
  );
}
