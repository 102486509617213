import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Spacer from '../ui/Spacer';

/**
 * A React component for offer Item
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Boolean} props.isLarge - The size of offer item.
 * @param {"medium" | "small" | "large"} props.spacerVariant - The size of the spacer component .
 *   Possible values: "medium", "small", "large".
 * @returns {JSX.Element} A React JSX element representing the component.
 */

export default function SkeletonOfferItem({ isLarge, count = 1 }) {
  if (isLarge) {
    return (
      <>
        {Array(count)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              className="h-full  pt-[45.4%] landscape:pt-0 landscape:w-[350px] landscape:h-[180px] rounded-2xl gap-3"
              key={index}
            />
          ))}
      </>
    );
  }
  return Array(count)
    .fill(0)
    .map((_, index) => (
      <div key={index} className="flex gap-2">
        <Skeleton height={110} width={110} className="rounded-md" />
        <div className="flex flex-col justify-center">
          <p className="text-xs">
            <Skeleton width={150} />
          </p>
          <Spacer size="small" />
          <h2>
            <Skeleton width={120} />
          </h2>
          <Spacer size="small" />
          <Skeleton width={100} height={30} className="rounded-full" />
        </div>
      </div>
    ));
}

// export default function SkeletonOfferItem({ count = 1 }) {
//   return Array(count)
//     .fill(0)
//     .map((_, index) => (
//       <div
//         key={index}
//         className="flex items-start gap-3 px-3 py-4 border-b border-header-primary/50"
//       >
//         <Skeleton height={50} width={50} className="rounded-lg" />
//         <div className="flex flex-col">
//           <h2 className="text-md">
//             <Skeleton width={200} />
//           </h2>
//           <p className="text-xs">
//             <Skeleton width={50} />
//           </p>
//         </div>
//       </div>
//     ));
// }
