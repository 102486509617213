export class IncentBanner {
  constructor(obj) {
    this.bannerId = obj.bannerId;
    this.appId = obj.appId;
    this.entityId = obj.entityId;
    this.entityType = obj.entityType;
    this.imageUrl = obj.imageUrl;
    this.status = obj.status;
  }

  static INCENT_BANNER_TYPES = [
    {
      id: 'url',

      display: 'URL'
    },
    {
      id: 'offer',

      display: 'Offer'
    },
    {
      id: 'spin_wheel',

      display: 'Spin Wheel'
    },
    {
      id: 'daily_reward',

      display: 'Daily Reward'
    },
    {
      id: 'wallet',

      display: 'Wallet'
    },
    {
      id: 'referral',

      display: 'Referral'
    },
    {
      id: 'contest',

      display: 'Contest'
    }
  ];
  isUrl() {
    return this.entityType === 'url';
  }
  isOffer() {
    return this.entityType === 'offer';
  }
  isSpinWheel() {
    return this.entityType === 'spin_wheel';
  }
  isDailyReward() {
    return this.entityType === 'daily_reward';
  }
  isWallet() {
    return this.entityType === 'wallet';
  }
  isReferral() {
    return this.entityType === 'referral';
  }
  isContest() {
    return this.entityType === 'contest';
  }

  static statusMap = {
    ACTIVE: { id: 'ACTIVE', display: 'Active', variant: 'success' },
    INTERNAL_PAUSED: {
      id: 'INTERNAL_PAUSED',
      display: 'Internal Paused',
      variant: 'warning'
    },
    PAUSED: { id: 'PAUSED', display: 'Paused', variant: 'warning' }
  };

  isActive() {
    return this.status === 'ACTIVE';
  }
  isInternalPaused() {
    return this.status === 'INTERNAL_PAUSED';
  }
  isPaused() {
    return this.status === 'PAUSED';
  }
  getIncentEntyTypeDisplay() {
    return (
      IncentBanner.INCENT_BANNER_TYPES.find(type => type.id === this.entityType)
        ?.display || 'N/A'
    );
  }
  static from(obj) {
    if (!obj) return null;
    return new IncentBanner({
      bannerId: obj.banner_id,
      appId: obj.banner_app_id,

      entityId: obj.data.entity_id,
      entityType: obj.data.entity_type,
      imageUrl: obj.image_url,

      status: obj.status
    });
  }
  static fromAll(arr) {
    if (!arr?.length) return null;
    return arr.map(IncentBanner.from);
  }
}
