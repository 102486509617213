'use client';

import React, { useMemo } from 'react';

import clsx from 'clsx';

import SortIcon from '/public/icons/sort.svg';
import SortAscIcon from '/public/icons/sort-asc.svg';
import SortDescIcon from '/public/icons/sort-desc.svg';
import { makeMap } from '@/utils';
import ArrowDownIcon from '/public/icons/down-arrow-filled.svg';
import { AndroidService } from '@/service/Android';
import { SORT_CHIP_CLICKED, SORT_UPDATED } from '@/constants/logs';
import { Popover, RadioGroup } from '@headlessui/react';
import { StorageService } from '@/service/StorageService';

export default function OfferSort({ onSubmit, activeSort, activeTags }) {
  const isSortActive = !!activeSort;

  const sortMap = useMemo(() => {
    return makeMap(SORT_OPTIONS, d => d.id);
  }, []);

  const handleSort = id => {
    // AndroidService.logEvent(
    //   SORT_CHIP_CLICKED,
    //   JSON.stringify({
    //     user_id: StorageService.getUserId()
    //   })
    // );
    if (id === sortMap.default.id) {
      AndroidService.logEvent(
        SORT_UPDATED,
        JSON.stringify({
          sort_opts: id || 'default',
          filter_opts: activeTags.join(','),
          user_id: StorageService.getUserId()
        })
      );
      onSubmit(null);
      return;
    }
    if (id !== activeSort) {
      AndroidService.logEvent(
        SORT_UPDATED,
        JSON.stringify({
          sort_opts: id || 'default',
          filter_opts: activeTags.join(','),
          user_id: StorageService.getUserId()
        })
      );
    }
    onSubmit(id);
  };

  const activeOption = sortMap[activeSort];

  return (
    <Popover>
      <Popover.Button className="outline-none ring-0">
        <div
          className={clsx(
            'rounded-full w-fit px-2 py-1 flex items-center gap-1 ',
            isSortActive ? 'bg-primary' : 'border-slate-400  border'
          )}
        >
          <div className="flex items-center gap-1">
            {isSortActive ? (
              <>
                {activeOption.type === 'asc' ? (
                  <SortAscIcon className="text-white" />
                ) : (
                  <SortDescIcon className="text-white" />
                )}
                <p className="text-sm text-white">{activeOption.display}</p>
                <ArrowDownIcon className="text-[0.6rem] text-white" />
              </>
            ) : (
              <>
                <SortIcon className={clsx('text-black')} />
                <p className={clsx('text-sm ', 'text-black')}>Sort</p>
                <ArrowDownIcon className="text-[0.6rem] text-black" />
              </>
            )}
          </div>
        </div>
      </Popover.Button>
      <Popover.Panel
        width="360px"
        className="absolute z-20 p-3 mt-1 bg-white border rounded-md border-primary"
      >
        <div>
          <RadioGroup
            value={activeSort === null ? sortMap.default.id : activeSort}
            // onChange={handleSort}
            className="flex flex-col gap-2"
          >
            {SORT_OPTIONS.map((d, i) => (
              <Popover.Button key={d.id}>
                <div
                  key={d.id}
                  onClick={() => handleSort(d.id)}
                  className={clsx(
                    'flex items-center gap-2 pb-1',
                    i === SORT_OPTIONS?.length - 1
                      ? 'border-none'
                      : 'border-b border-primary'
                  )}
                >
                  <RadioGroup.Option value={d.id} className="w-full">
                    {({ checked }) => (
                      <div className="flex items-center w-full gap-2">
                        <p className="text-sm text-primary">
                          {d.display}
                          {!!d.type
                            ? d.type === 'asc'
                              ? `: Low to High`
                              : `: High to Low`
                            : null}
                        </p>
                        <div
                          className={clsx(
                            'w-4 h-4 border rounded-full border-primary flex items-center justify-center ml-auto'
                          )}
                        >
                          {checked && (
                            <div className="w-4/6 rounded-full h-4/6 bg-primary" />
                          )}
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                </div>
              </Popover.Button>
            ))}
          </RadioGroup>
        </div>
      </Popover.Panel>
    </Popover>
  );
}

const SORT_OPTIONS = [
  {
    id: 'default',
    display: 'Default (Relevance)'
  },
  {
    id: 'ttfp',
    display: 'Time',
    type: 'asc'
  },
  {
    id: '-ttfp',
    display: 'Time',
    type: 'desc'
  },
  {
    id: '-payout',
    display: 'Reward',
    type: 'desc'
  },
  {
    id: 'payout',
    display: 'Reward',
    type: 'asc'
  }
];
