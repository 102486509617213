import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '/public/close.svg';
import useLandscape from '@/hooks/useLandscape';
import ExitAppDialog from '@/components/dialogs/ExitAppDialog';

const HEADER_HEIGHT = 200;

function StickyHeader({ children, bannerHeight }) {
  const [showHelpText, setShowHelpText] = useState(false);
  const [confirmExitOpen, setConfirmExitOpen] = useState(false);
  const isLandscape = useLandscape();
  const onOfferwallClose = () => {
    setConfirmExitOpen(true);
  };

  useEffect(() => {
    function handleScroll() {
      const position = window.scrollY;
      setShowHelpText(position > HEADER_HEIGHT + bannerHeight);
    }
    setTimeout(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
    }, 1000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const showSmallHeader = showHelpText && !isLandscape;
  return (
    <>
      <motion.div
        initial={{ y: -110 }}
        animate={{ y: showSmallHeader ? 0 : -110 }}
        transition={{ duration: 0.3 }}
        className="fixed top-0 z-50 w-full"
      >
        <div className="flex items-center justify-center px-2 py-4 bg-primary">
          <h1 className="text-sm font-semibold text-center text-white">
            Complete offers to get free rewards
          </h1>
          <CloseIcon
            className="absolute text-2xl text-white rounded-md right-2 bg-black/40"
            onClick={onOfferwallClose}
          />
        </div>
        {showSmallHeader && children}
      </motion.div>
      <ExitAppDialog
        isOpen={confirmExitOpen}
        onOpenChange={open => setConfirmExitOpen(open)}
      />
    </>
  );
}

export default StickyHeader;
