import { Payout } from './offer';

export class Wallet {
  constructor(obj) {
    this.balance = obj.balance;
    this.conversionRate = obj.conversionRate;
  }

  static from(obj) {
    return new Wallet({
      balance: Payout.from(obj.balance),
      conversionRate: obj.conversion_rate
    });
  }

  static fromAll(data) {
    return data.map(Wallet.from);
  }
}
