'use client';

import React, { useEffect, useState } from 'react';
import Dialog from '@/components/ui/Dialog';

import Button from '@/components/ui/Button';

import FilterIcon from '/public/icons/filter.svg';
import CloseIcon from '/public/close.svg';
import FinanceIcon from '/public/icons/finance-tag.svg';
import DepositIcon from '/public/icons/deposit-tag.svg';
import GamingIcon from '/public/icons/gaming-tag.svg';
import SurveryIcon from '/public/icons/survey-tag.svg';
import NewIcon from '/public/icons/new-tag.svg';
import { AndroidService } from '@/service/Android';
import { FILTER_CLICKED, FILTER_UPDATED } from '@/constants/logs';
import clsx from 'clsx';
import { StorageService } from '@/service/StorageService';

export default function OfferFilter({ onSubmit, activeSort, activeTags = [] }) {
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const filterOptions = FILTER_OPTIONS;

  // Sync initial tags with selected filters
  useEffect(() => {
    const mappedTags = {};
    activeTags.forEach(tag => {
      const tagGroup = filterOptions.find(group =>
        group.filters.some(filter => filter.id === tag)
      );
      if (tagGroup) mappedTags[tagGroup.id] = tag;
    });
    setSelectedFilters(mappedTags);
  }, [activeTags]);

  // Handle toggling Gaming filter outside the popup
  const handleOutsideGamingToggle = () => {
    const isActive = selectedFilters['category'] === 'GAMING';
    const newFilters = isActive
      ? { ...selectedFilters, category: undefined } // Remove Gaming
      : { ...selectedFilters, category: 'GAMING' }; // Add Gaming

    AndroidService.logEvent(
      FILTER_CLICKED,
      JSON.stringify({
        filter_opts: isActive ? 'GAMING_REMOVED' : 'GAMING',
        user_id: StorageService.getUserId()
      })
    );
    setSelectedFilters(newFilters);

    // Apply filters immediately
    const tags = Object.values(newFilters).filter(Boolean);
    onSubmit(tags);
  };
  // Handle toggling Gaming filter outside the popup
  const handleOutsideOneStepToggle = () => {
    const isActive = selectedFilters['type'] === 'SINGLE_STEP';
    const newFilters = isActive
      ? { ...selectedFilters, type: undefined } // Remove one step
      : { ...selectedFilters, type: 'SINGLE_STEP' }; // Add one step

    AndroidService.logEvent(
      FILTER_CLICKED,
      JSON.stringify({
        filter_opts: isActive ? 'SINGLE_STEP_REMOVED' : 'SINGLE_STEP',
        user_id: StorageService.getUserId()
      })
    );
    setSelectedFilters(newFilters);

    // Apply filters immediately
    const tags = Object.values(newFilters).filter(Boolean);
    onSubmit(tags);
  };

  // Handle toggling New filter outside the popup
  const handleOutsideNewToggle = () => {
    const isActive = selectedFilters['feature'] === 'NEW';
    const newFilters = isActive
      ? { ...selectedFilters, feature: undefined } // Remove one step
      : { ...selectedFilters, feature: 'NEW' }; // Add one step

    AndroidService.logEvent(
      FILTER_CLICKED,
      JSON.stringify({
        filter_opts: isActive ? 'NEW_REMOVED' : 'NEW',
        user_id: StorageService.getUserId()
      })
    );
    setSelectedFilters(newFilters);

    // Apply filters immediately
    const tags = Object.values(newFilters).filter(Boolean);
    onSubmit(tags);
  };

  // Handle opening and closing the filter popup
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle toggling filters inside the popup
  const handleToggleFilter = ({ groupId, filterId }) => {
    setSelectedFilters(prev => {
      if (prev[groupId] === filterId) {
        const updated = { ...prev };
        delete updated[groupId];
        return updated;
      }
      return { ...prev, [groupId]: filterId };
    });
  };

  // Clear all filters
  const handleClear = () => setSelectedFilters({});

  // Apply filters when "Apply" is clicked
  const handleApply = () => {
    const tags = Object.values(selectedFilters).filter(Boolean);
    if (arraysEqual(tags, activeTags)) {
      handleClose();
      return;
    }

    AndroidService.logEvent(
      FILTER_UPDATED,
      JSON.stringify({
        filter_opts: tags.join(','),
        sort_opts: activeSort || 'default',
        user_id: StorageService.getUserId()
      })
    );
    onSubmit(tags);
    handleClose();
  };

  const noOfSelectedFilters = Object.keys(selectedFilters).filter(
    key => selectedFilters[key]
  ).length;
  const isGamingActive = selectedFilters['category'] === 'GAMING';
  const isOneStepActive = selectedFilters['type'] === 'SINGLE_STEP';
  const isNewActive = selectedFilters['feature'] === 'NEW';

  return (
    <>
      <div className="flex items-center gap-1 overflow-auto">
        {/* Main Filter Button */}
        <div
          className={clsx(
            'rounded-full border-[1px] px-2 py-1 flex items-center gap-1',
            noOfSelectedFilters ? 'border-primary' : 'border-slate-400'
          )}
          onClick={handleOpen}
        >
          <FilterIcon
            className={clsx(
              noOfSelectedFilters ? 'text-primary' : 'text-black'
            )}
          />
          <div className="flex items-center gap-1">
            <p
              className={clsx(
                'text-sm',
                noOfSelectedFilters ? 'text-primary' : 'text-black'
              )}
            >
              Filter
            </p>
            {noOfSelectedFilters > 0 && (
              <p className="grid w-4 h-4 text-white rounded-full text-xxs bg-primary place-items-center">
                {noOfSelectedFilters}
              </p>
            )}
          </div>
        </div>

        {/* Gaming One-Tap Filter */}
        <div
          className={clsx(
            'rounded-full border-[1px] px-2 py-1 flex items-center gap-1',
            isGamingActive
              ? 'bg-primary text-white border-primary'
              : 'bg-white text-black border-slate-400'
          )}
          onClick={handleOutsideGamingToggle}
        >
          <p className="select-none text-sm whitespace-nowrap">Gaming</p>
          {isGamingActive && <CloseIcon className="text-white" />}
        </div>
        {/* Gaming One-Tap Filter */}
        <div
          className={clsx(
            'rounded-full border-[1px] px-2 py-1 flex items-center gap-1',
            isOneStepActive
              ? 'bg-primary text-white border-primary'
              : 'bg-white text-black border-slate-400'
          )}
          onClick={handleOutsideOneStepToggle}
        >
          <p className="select-none text-sm whitespace-nowrap">One-Step</p>
          {isOneStepActive && <CloseIcon className="text-white" />}
        </div>
        {/* Gaming One-Tap Filter */}
        <div
          className={clsx(
            'rounded-full border-[1px] px-2 py-1 flex items-center gap-1',
            isNewActive
              ? 'bg-primary text-white border-primary'
              : 'bg-white text-black border-slate-400'
          )}
          onClick={handleOutsideNewToggle}
        >
          <p className="select-none text-sm whitespace-nowrap">New</p>
          {isNewActive && <CloseIcon className="text-white" />}
        </div>
      </div>

      {/* Filter Popup */}
      <Dialog
        isOpen={open}
        onOpenChange={handleClose}
        position="bottom"
        classes={{ wrapper: '!p-0', closeIcon: 'text-white' }}
        className="border-none rounded-none !p-0 bg-white"
        closable="true"
        contentClassName="mt-0"
      >
        <div className="p-4 border-b border-slate-400 bg-primary">
          <p className="font-semibold text-white">
            Filter{' '}
            {noOfSelectedFilters > 0 && (
              <span className="text-sm font-medium">{`(${noOfSelectedFilters} selected)`}</span>
            )}
          </p>
        </div>
        <div className="px-6 border-b border-slate-400">
          {filterOptions.map(group => (
            <FilterGroup
              key={group.id}
              filterGroup={group}
              selectedFilters={selectedFilters}
              handleToggleFilter={handleToggleFilter}
            />
          ))}
        </div>
        <div className="flex items-center justify-between px-6 py-3">
          <Button
            variant="ghost"
            className="text-primary"
            onClick={handleClear}
          >
            Clear all
          </Button>
          <Button
            className="w-32 rounded-md"
            variant="contained"
            color="primary"
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
      </Dialog>
    </>
  );
}

const FilterGroup = ({ filterGroup, selectedFilters, handleToggleFilter }) => {
  const { filters, label, id: groupId } = filterGroup;

  return (
    <div className="flex flex-col gap-3 py-4 border-b border-slate-600">
      <p className="text-black">{label}</p>
      <div className="flex flex-wrap gap-2">
        {filters.map(filter => {
          const isSelected = selectedFilters[groupId] === filter.id;
          const Icon = filter.icon;
          return (
            <div
              key={filter.id}
              className={clsx(
                'p-2 rounded-lg border-x border-y min-w-[120px] flex flex-col items-start gap-2',
                isSelected ? 'bg-primary/5 border-primary' : 'border-slate-500'
              )}
              onClick={() =>
                handleToggleFilter({ groupId, filterId: filter.id })
              }
            >
              {Icon && (
                <Icon
                  className={clsx(
                    'text-lg',
                    isSelected ? 'text-primary' : 'text-slate-500'
                  )}
                />
              )}
              <p
                className={clsx(
                  'select-none text-sm',
                  isSelected ? 'text-primary' : 'text-slate-500'
                )}
              >
                {filter.label}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FILTER_OPTIONS = [
  {
    id: 'type',
    label: 'Offer Type',
    filters: [
      { id: 'SINGLE_STEP', label: 'One-Step' },
      { id: 'MULTI_REWARD', label: 'Multi-Reward' }
    ]
  },
  {
    id: 'category',
    label: 'Offer Category',
    filters: [
      {
        id: 'GAMING',
        label: 'Gaming',
        icon: GamingIcon
      },
      {
        id: 'FINANCE',
        label: 'Finance',
        icon: FinanceIcon
      },
      {
        id: 'SURVEY',
        label: 'Survey',
        icon: SurveryIcon
      }
    ]
  },
  {
    id: 'feature',
    label: 'Offer Feature',
    filters: [
      {
        id: 'DEPOSIT',
        label: 'Need Deposit',
        icon: DepositIcon
      },
      {
        id: 'NEW',
        label: 'New',
        icon: NewIcon
      }
    ]
  }
];

function arraysEqual(arr1, arr2) {
  return (
    arr1.length === arr2.length && arr1.every((val, idx) => val === arr2[idx])
  );
}
