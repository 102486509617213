'use client';

import { Creative, Offer, OfferV6 } from '@/model/offer';
import { twMerge } from 'tailwind-merge/es5';
import Button from '../ui/Button';
import OfferPayout from '../OfferPayout';
import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Link from 'next/link';
import clsx from 'clsx';
import useIntersection from '@/hooks/useIntersection';
import { useInView } from 'framer-motion';
import { AndroidService } from '@/service/Android';
import useService from '@/hooks/useService';
import { toast } from '@/utils';
import { OfferService } from '@/service/Offer';
import { SignalService } from '@/service/Signal';
import { useRouter } from 'next/navigation';
import { formatServerErrors } from '@/utils/withDefaultUiHandler';
import { OFFER_CTA_CLICKED_V2 } from '@/constants/logs';
import { StorageService } from '@/service/StorageService';

/**
 * A React component for offer Item
 *
 * @component
 * @param {Object} props - The component props.
 * @param {OfferV6} props.offer - The offer.
 * @param {Creative} props.offer.creative - The offer.
 * @param {Boolean} props.isLarge - The size of offer item.
 * @param {String} props.containerClass - The class of offer container.
 * @param {Number} props.index - The index of offer in the list.
 * @param {Boolean} props.isPermission - is the enable dialog permission open
 * @param {Function} props.setIsPermissionOpen - control enable dialog permission dialog
 * @returns {JSX.Element} A React JSX element representing the component.
 * @example
 */

export default function OfferCardV2({
  offer,
  containerClass,
  index: offerIndex,
  setIsPermissionOpen,
  children,
  activeTags,
  activeSort
}) {
  const ref = useRef(null);
  const isVisible = useInView(ref, { once: true });
  const [isCtaLoading, setIsCtaLoading] = useState();
  const markOfferOngoing = useService(SignalService.markOfferOngoing);
  const getOfferCtaUrl = useService(OfferService.getOfferCtaUrl);
  const router = useRouter();

  useEffect(() => {
    if (isVisible) {
      AndroidService.logEvent(
        'offer_view',
        JSON.stringify({
          offer_id: offer.offerId,
          position: offerIndex
        })
      );
    }
  }, [isVisible]);

  async function markOngoing(offer, postbackReward) {
    if (offer.userStatus !== 'NIL') return;
    let encryptedPayload;
    try {
      encryptedPayload = AndroidService.encrypt(
        Number(offer.offerId),
        Number(postbackReward.rewardId),
        offer.androidPackageName
      );
    } catch {
      encryptedPayload = AndroidService.encrypt(
        Number(offer.offerId),
        Number(postbackReward.rewardId)
      );
    } finally {
      try {
        await markOfferOngoing.call(encryptedPayload);
      } catch (err) {
        throw formatServerErrors(err).join(' ');
      }
    }
  }

  async function startOffer() {
    if (!offer) return;

    if (
      offer?.isUsagePermissionRequired() &&
      !AndroidService.isUsageStatsPermissionGranted()
    ) {
      AndroidService.logEvent('usage_permission_requested', null);
      setIsPermissionOpen(true);
      return;
    }

    setIsCtaLoading(true);

    const { gaId } = AndroidService.getTrackingData();
    try {
      await markOngoing(offer, offer?.postbackReward);
      const { url } = await getOfferCtaUrl.call({
        rewardId: offer?.postbackReward?.rewardId,
        gaId
      });

      AndroidService.startOffer(
        JSON.stringify({ offer: offer?.toAndroid() }),
        url
      );
      openOfferDetails();
    } catch (e) {
      console.log(e, 'errorrrrrr');
      toast(e);
    } finally {
      setIsCtaLoading(false);
    }
    AndroidService.logEvent(
      OFFER_CTA_CLICKED_V2,
      JSON.stringify({
        offer_id: offer?.offerId,
        user_id: StorageService.getUserId(),
        sort_opts: activeSort || 'default',
        filter_opts: activeTags.join(',')
      })
    );
  }

  const handleCardClick = () => {
    AndroidService.logEvent(
      'offer_click',
      JSON.stringify({ offer_id: offer?.offerId })
    );
    // if (offer.hasMultipleRewards()) {
    //   openOfferDetails();
    //   return;
    // }
    // startOffer();
  };

  const openOfferDetails = e => {
    e?.stopPropagation();
    router.push(`/offer/${offer?.offerId}`);
  };

  return children({
    offer,
    containerClass,
    isCtaLoading,
    openOfferDetails,
    handleCardClick,
    ref
  });
}
