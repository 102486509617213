import { AndroidService } from '@/service/Android';
import { simplifyNumber } from '@/utils';
import React from 'react';
import { twMerge } from 'tailwind-merge/es5';

/**
 * A React component for offer Payout
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Number} props.amount - The offer amount.
 * @param {Boolean} props.showFullAmount - The offer amount.
 * @param {Object} props.style - Style for currency image.
 * @param {Object} props.classes - Style for currency image.
 * @param {"medium" | "small" | "large"} props.size - The size of the component.
 *   Possible values: "medium", "small", "large".
 * @returns {JSX.Element} A React JSX element representing the component.

 */

export default function OfferPayout({ amount, size, classes, showFullAmount }) {
  const currencyIconUrl = AndroidService.getCurrencyIconUrl();
  const currencyName = AndroidService.getCurrencyName();

  const amountToDisplay = showFullAmount
    ? parseFloat(amount).toFixed(2)
    : simplifyNumber(amount);

  const imageClass =
    size === 'large' ? 'h-[25px]' : size === 'medium' ? 'h-[23px]' : 'h-[20px]';

  const textClass =
    size === 'large' ? 'text-lg' : size === 'medium' ? 'text-base' : 'text-sm';

  return (
    <>
      {currencyIconUrl ? (
        <img
          src={currencyIconUrl}
          alt={currencyName}
          className={twMerge(imageClass, 'ml-1 aspect-square', classes?.image)}
        />
      ) : null}
      {typeof amount === 'number' ? (
        <p className={twMerge('ml-1', textClass, classes?.text)}>
          {amountToDisplay}
        </p>
      ) : null}
      {!currencyIconUrl && !!currencyName ? (
        <p className={twMerge('ml-1', textClass, classes?.text)}>
          {currencyName}
        </p>
      ) : null}
    </>
  );
}
