import Link from 'next/link';
import React from 'react';
import { twMerge } from 'tailwind-merge/es5';
import ButtonLoader from '../ButtonLoader';

/**
 * A Custom React component for Button
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onClick - The onClick handler function.
 * @param {String} props.className - The className for button.
 * @param {Boolean} props.shine - To enable shine effect for button.
 * @param {Boolean} props.isLoading - To enable loading effect for button.
 * @param {"contained"| "outlined" | "text", "glass"} props.variant - The variant of the component.
 *  Possible values: "contained", "outlined" , "text"
 * @param {"primary" | "secondary"} props.color - The variant of the component.
 *  Possible values: "primary", "secondary" 
 * @param {JSX.Element} props.children - Children.
 * @param {JSX.Element} props.as - Children.
 * @returns {JSX.Element} A React JSX element representing the component.

 */

export default function Button({
  children,
  variant,
  className,
  shine,
  color,
  isLoading,
  disabled,
  as: Element = 'button',
  ...props
}) {
  const variantClass =
    variant === 'outlined'
      ? 'border-[1px] p-1 px-4'
      : variant === 'contained'
      ? 'w-auto p-1 px-4'
      : variant === 'glass'
      ? 'glass p-1 px-4'
      : 'p-0';

  const getColorClass = () => {
    let colorClass;
    if (color === 'primary') {
      if (variant === 'contained') {
        colorClass = 'bg-primary text-white';
      } else if (variant === 'outlined') {
        colorClass = 'border-primary text-primary';
      } else if (variant === 'glass') {
        colorClass = 'text-primary-darken';
      } else {
        colorClass = 'text-primary';
      }
    } else {
      if (variant === 'contained') {
        colorClass = 'bg-white text-primary';
      } else if (variant === 'outlined') {
        colorClass = 'border-white text-white bg-primary';
      } else {
        colorClass = 'text-white';
      }
    }
    return colorClass;
  };
  const disabledClass = disabled ? 'disabled-btn pointer-events-none' : '';
  const shineClass = shine && !disabled ? 'shine-btn' : '';

  return (
    <Element
      className={twMerge(
        'flex items-center justify-center rounded-full relative',
        variantClass,
        getColorClass(),
        className,
        shineClass,
        disabledClass
      )}
      {...props}
    >
      {isLoading ? (
        <ButtonLoader
          className={twMerge(
            color === 'secondary' ? 'text-white' : 'text-primary',
            'font-inherit'
          )}
        />
      ) : (
        children
      )}
      {/* {shine && <span className="shine" />} */}
    </Element>
  );
}

{
  /* <button
className={twMerge(
  'flex items-center justify-center rounded-full p-1 px-4',
  btnClassName,
  className,
  shine && 'relative'
)}
onClick={onClick}
>
{isLoading ? (
  <ButtonLoader
    className={variant === 'secondary' ? 'text-white' : 'text-primary'}
  />
) : (
  children
)}
{/* {shine && <span className="shine" />} */
}
