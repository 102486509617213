'use client';

import { useEffect, useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonOfferItem from '@/components/skeletons/SkeletonOfferItem';
import NoOffers from '@/components/NoOffers';
import RegularOfferCardV2 from '@/components/OfferCards/RegularOfferCardV2';
import ReachUs from '@/components/ReachUs';
import usePaginatedService from '@/hooks/usePaginatedService';
import { OfferService } from '@/service/Offer';
import SomethingWrong from '@/components/SomethingWrong';
import useLandscape from '@/hooks/useLandscape';
import StickyBottom from './components/StickyBottom';
import ActiveListLoader from './components/ActiveListLoader';
import { AndroidService } from '@/service/Android';
import VpnDialog from '@/components/VpnDialog';
import EnableUsageAccessDialog from '@/components/dialogs/EnableUsageAccessDialog';
import { DIALOG_CLOSE_TYPES } from '@/components/ui/Dialog';
import OfferCardV2 from '@/components/OfferCards/OfferCardV2';
import OfferSort from './components/OfferSort';
import OfferFilter from './components/OfferFilter';
import { OfferV6 } from '@/model/offer';
import { makeMap } from '@/utils';
import NoFilterOffers from './components/NoFilterOffers';
import StickyHeader from './components/StickyHeader';
import TopBanner from './components/TopBanner';
import { useSearchParams } from 'next/navigation';

const OfferActions = ({ tags, sort, setSort, setTags }) => (
  <div className="flex items-center gap-1 pt-1 mx-2 bg-white overflow-auto">
    <div className="my-2">
      <OfferSort activeTags={tags} activeSort={sort} onSubmit={setSort} />
    </div>
    <div className="my-2">
      <OfferFilter activeSort={sort} activeTags={tags} onSubmit={setTags} />
    </div>
  </div>
);

const OfferContent = ({
  activeOffers,
  isLoading,
  loadMoreData,
  scrollableRef,
  isLandscape,
  setIsPermissionOpen,
  tags,
  sort,
  tagMap
}) => {
  const itemCount = activeOffers?.length + (isLoading ? 1 : 0);

  return (
    <div className="relative h-[calc(100vh_-_150px)] landscape:h-unset landscape:w-screen">
      <AutoSizer disableWidth>
        {({ height }) => (
          <InfiniteScroll
            dataLength={
              itemCount < 10 ? itemCount + (10 - itemCount) + 2 : itemCount + 2
            }
            next={loadMoreData}
            hasMore={true}
            height={isLandscape ? height : undefined}
            className="landscape:flex landscape:py-4 landscape:pl-4"
            ref={scrollableRef}
          >
            <div className="p-4 pt-1 landscape:flex landscape:p-0">
              <div className="grid gap-3 landscape:grid landscape:grid-flow-col-dense landscape:items-center portrait:mb-5">
                {activeOffers.map((offer, index) => (
                  <OfferCardV2
                    key={offer.offerId}
                    offer={offer}
                    index={index + 3}
                    setIsPermissionOpen={setIsPermissionOpen}
                    activeTags={tags}
                    activeSort={sort}
                  >
                    {props => <RegularOfferCardV2 {...props} tagMap={tagMap} />}
                  </OfferCardV2>
                ))}
                {isLoading && <SkeletonOfferItem />}
              </div>
            </div>
            <ReachUs className="landscape:min-w-[350px] landscape:-m-4 landscape:ml-4" />
            <StickyBottom scrollContainer={scrollableRef?.current?.el} />
          </InfiniteScroll>
        )}
      </AutoSizer>
    </div>
  );
};

export default function OfferwallHomepage() {
  const containerRef = useRef(null);
  const scrollableRef = useRef(null);
  const isLandscape = useLandscape();
  const [isPermissionOpen, setIsPermissionOpen] = useState(false);
  const [vpnDialogOpen, setVpnDialogOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [sort, setSort] = useState(null);
  const [bannerHeight, setBannerHeight] = useState(0);
  const tagMap = useMemo(() => makeMap(OfferV6.TAGS, d => d.id), []);

  const {
    data: activeOffers,
    loadMoreData,
    hasMoreData,
    isLoaded,
    refreshData,
    isLoading,
    error
  } = usePaginatedService(OfferService.getActiveOffers, {
    makeFirstCall: false,
    count: 10,
    serviceArgs: { tags, sort }
  });

  useEffect(() => {
    if (AndroidService.isVPNEnabled()) {
      setVpnDialogOpen(true);
    }
    window.owResume = function () {
      if (!AndroidService.isVPNEnabled()) {
        if (vpnDialogOpen && refreshData) {
          refreshData();
        }
        setVpnDialogOpen(false);
      } else {
        setVpnDialogOpen(true);
      }
    };
    return () => {
      window.owResume = () => {};
    };
  }, [refreshData, vpnDialogOpen]);

  useEffect(() => {
    refreshData();
  }, [tags, sort]);

  const handleRejectPermission = () => {
    AndroidService.logEvent('usage_permission_rejected', null);
    setIsPermissionOpen(false);
  };

  const handleRequestPermission = () => {
    AndroidService.getUsageAccessPermission();
    setIsPermissionOpen(false);
  };

  return (
    <div>
      <div>
        <TopBanner getBannerHeight={setBannerHeight} />
      </div>

      <StickyHeader bannerHeight={bannerHeight}>
        <OfferActions
          tags={tags}
          sort={sort}
          setSort={setSort}
          setTags={setTags}
        />
      </StickyHeader>

      <VpnDialog open={vpnDialogOpen} />
      <EnableUsageAccessDialog
        isOpen={isPermissionOpen}
        onOpenChange={(openState, type) => {
          setIsPermissionOpen(openState);
          if (type === DIALOG_CLOSE_TYPES.CLOSE_ICON) {
            handleRejectPermission();
          }
        }}
        onRequestPermission={handleRequestPermission}
      />
      <OfferActions
        tags={tags}
        sort={sort}
        setSort={setSort}
        setTags={setTags}
      />

      {!isLoaded || (isLoading && !activeOffers) ? (
        <ActiveListLoader className="pt-1" />
      ) : error ? (
        <SomethingWrong
          onRefresh={refreshData}
          classes={{
            container:
              'h-[calc(100vh_-_200px)] landscape:h-[calc(100vh_-_60px)] landscape:w-screen',
            header: 'landscape:text-xl'
          }}
        />
      ) : !activeOffers?.length ? (
        tags?.length ? (
          <NoFilterOffers onReset={() => setTags([])} />
        ) : (
          <NoOffers onRefresh={refreshData} />
        )
      ) : (
        <OfferContent
          activeOffers={activeOffers}
          isLoading={isLoading}
          loadMoreData={loadMoreData}
          scrollableRef={scrollableRef}
          isLandscape={isLandscape}
          setIsPermissionOpen={setIsPermissionOpen}
          tags={tags}
          sort={sort}
          tagMap={tagMap}
        />
      )}
    </div>
  );
}
