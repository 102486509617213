import { useEffect, useRef, useState } from 'react';

export const useConnStatus = () => {
  const [isOnline, setIsOnline] = useState(() => {
    if (typeof window === 'undefined') return true;
    return window.navigator.onLine;
  });
  const onlineRef = useRef(isOnline);

  useEffect(() => {
    Object.defineProperty(window.navigator, 'onLine', {
      get: function () {
        return onlineRef.current;
      },
      configurable: true
    });

    function connEventListener(e) {
      const isOnline = e.type === 'online';
      onlineRef.current = isOnline;
      setIsOnline(isOnline);
    }

    window.addEventListener('online', connEventListener);
    window.addEventListener('offline', connEventListener);

    return () => {
      window.removeEventListener('online', connEventListener);
      window.removeEventListener('offline', connEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isOnline;
};
