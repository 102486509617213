import Button from '../ui/Button';
import Dialog from '../ui/Dialog';
import EnableAccessSVG from '/public/svgs/enable-access.svg';
export default function EnableUsageAccessDialog({
  onRequestPermission,
  isOpen,
  onOpenChange
}) {
  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      closable
      className="landscape:max-w-sm"
      noOutsideClickClose
      actions={({ close }) => (
        <div className="flex flex-col justify-center gap-1 px-6 mb-2">
          <Button
            color="primary"
            variant="contained"
            className="w-full h-12"
            onClick={() => {
              onRequestPermission();
              close();
            }}
          >
            Enable
          </Button>
        </div>
      )}
    >
      <div className="flex items-center justify-center max-w-sm">
        <EnableAccessSVG className="w-48 max-w-screen-sm landscape:w-24" />
      </div>
      <h2 className="mb-2 font-semibold text-center text-dimmed font-lg/loose">
        Enable Usage Access
      </h2>
      <div className="text-center text-sm leading-[24px] font-normal text-dimmed/60">
        Enable usage access to get amazing rewards from this offer now!
      </div>
    </Dialog>
  );
}
