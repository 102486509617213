'use client';
import React, { useRef, useState } from 'react';

import PoweredBy from '../../../components/PoweredBy';

import { AndroidService } from '@/service/Android';
import Link from 'next/link';
import IconHeader from '@/components/IconHeader';
import HomeIcon from '@/components/ui/HomeIcon';
import ExitAppDialog from '@/components/dialogs/ExitAppDialog';

export default function HomeHeader({ isOfferwallLoaded }) {
  const [confirmExitOpen, setConfirmExitOpen] = useState(false);
  const headerRef = useRef();

  const onOfferwallClose = () => {
    setConfirmExitOpen(true);
  };

  return (
    <>
      <div
        ref={headerRef}
        className="overflow-hidden relative  h-[60px] landscape:h-[60px]  bg-primary-darken "
        // className="overflow-hidden relative  h-[150px] landscape:h-[60px]   "
      >
        {/* <img
          src={'/loader-bg.png'}
          alt="header"
          className="absolute top-0 bottom-0 left-0 right-0 object-cover w-full h-full"
        />
        <div className="absolute top-0 bottom-0 left-0 right-0 w-screen bg-black/30" />
        <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center landscape:flex-row landscape:justify-between landscape:px-14">
          <img
            src={'/loader-icon.png'}
            alt="app-icon"
            className={`aspect-square h-[70px] landscape:h-[40px] portrait:mb-4 ${
              AndroidService.isDebugEnabled() && 'mt-4'
            }`}
          />
          <PoweredBy
            classes={{
              container:
                'absolute bottom-2 translate-[50%, 50%]  landscape:static landscape:justify-self-end',
              text: 'text-[0.5rem] font-bold leading-none landscape:text-[0.65rem] landscape:relative landscape:-bottom-[1px]',
              image: 'landscape:text-[24px]'
            }}
          /> */}
        <IconHeader className="" onClose={onOfferwallClose}>
          <IconHeader.Left>
            <Link
              style={{ pointerEvents: !isOfferwallLoaded ? 'none' : '' }}
              href={'/profile'}
            >
              <HomeIcon className="text-3xl" />
            </Link>
          </IconHeader.Left>
        </IconHeader>
        {/* </div> */}
      </div>
      <ExitAppDialog
        isOpen={confirmExitOpen}
        onOpenChange={open => setConfirmExitOpen(open)}
      />
    </>
  );
}
