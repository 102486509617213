'use client';
import React from 'react';
import VpnSVG from '/public/svgs/vpn.svg';
import Dialog from '../ui/Dialog';
import { AndroidService } from '@/service/Android';

export default function VpnDialog({ open }) {
  const isDebug = AndroidService.isDebugEnabled();
  if (isDebug) return null;

  return (
    <Dialog
      isOpen={open}
      closable={false}
      noOutsideClickClose
      className="max-w-sm"
    >
      <div className="flex flex-col items-center justify-center ">
        <div className="flex items-center justify-center max-w-sm">
          <VpnSVG className="w-72 max-w-screen-sm landscape:max-h-[30vh]" />
        </div>
        <h2 className="mb-2 font-semibold text-center text-dimmed font-lg/loose">
          Oops, We have Detected an VPN
        </h2>
        <div className="flex gap-2">
          <div className="text-center text-sm leading-[24px] font-normal text-dimmed/60">
            To ensure you have a smooth and secure experience, you have to turn
            off VPN.
          </div>
        </div>
      </div>
    </Dialog>
  );
}
