'use client';
import React, { useEffect } from 'react';
import OfflineSVG from '/public/svgs/offline.svg';
import { useConnStatus } from '@/hooks/useConnStatus';
import Dialog from '../ui/Dialog';
import { AndroidService } from '@/service/Android';

export default function OfflineDialog() {
  const isOnline = useConnStatus();

  useEffect(() => {
    if (!isOnline) AndroidService.logEvent('offerwall_offline', null);
  }, [isOnline]);

  if (isOnline) return null;

  return (
    <Dialog
      isOpen={true}
      closable={false}
      noOutsideClickClose
      className="max-w-sm"
    >
      <div className="flex flex-col items-center justify-center ">
        <div className="flex items-center justify-center max-w-sm">
          <OfflineSVG className="w-48 max-w-screen-sm landscape:max-h-[30vh]" />
        </div>
        <h2 className="mb-2 font-semibold text-center text-dimmed font-lg/loose">
          Oops, No Internet Connection
        </h2>
        <div className="flex gap-2">
          <div className="text-center text-sm leading-[24px] font-normal text-dimmed/60">
            Please turn on your WiFi or mobile data to access the available
            offers.
          </div>
        </div>
      </div>
    </Dialog>
  );
}
