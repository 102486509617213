import { apiAxiosV1 } from '@/configs/axios';

export const SignalService = {
  async markOfferOngoing(payload) {
    const url = `/user_offer/offerwall/signal/ongoing`;
    const res = await apiAxiosV1.post(url, payload);
    return res;
  },
  async markLocalVerified(payload) {
    const url = `/user_offer/offerwall/signal/local_verify`;
    const res = await apiAxiosV1.post(url, payload);
    return res;
  }
};
