import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '/public/close.svg';
import useLandscape from '@/hooks/useLandscape';
import Link from 'next/link';
import useService from '@/hooks/useService';
import { OfferService } from '@/service/Offer';
const HEADER_HEIGHT = 150;
const LANDSCAPE_SCROLL_LEFT = 300;

function StickyBottom({ scrollContainer }) {
  const [showChip, setShowChip] = useState(false);
  const isLandscape = useLandscape();
  const { call, data, error, isLoaded } = useService(
    OfferService.getOngoingOffersCount
  );
  useEffect(() => {
    call();
    let element = isLandscape ? scrollContainer || window : window;
    let lastScrollPosition = 0;
    function handleScroll(e) {
      let position;

      if (isLandscape) {
        let position = e.target.scrollLeft || 0;
        setShowChip(position > LANDSCAPE_SCROLL_LEFT);
        return;
      }

      position = window.scrollY;
      const isScrollingDown = position < lastScrollPosition;

      lastScrollPosition = position;

      if (position < HEADER_HEIGHT) {
        setShowChip(false);
      } else {
        setShowChip(!isScrollingDown);
      }
    }

    setTimeout(() => {
      element.addEventListener('scroll', handleScroll);
    }, 1000);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [isLandscape, scrollContainer]);

  if (!isLoaded) return null;
  if (error) return null;
  if (!data?.count) return null;

  return (
    <motion.div
      initial={{ y: isLandscape ? -100 : 0 }}
      animate={{ y: !showChip ? 0 : isLandscape ? -200 : 100 }}
      transition={{ duration: 0.3 }}
      className={`fixed mx-auto inset-x-0 bottom-4  bg-white shadow-lg max-w-fit rounded-full overflow-hidden landscape:top-[6px] landscape:bottom-auto border border-[#E7E7E7]`}
    >
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-primary/10 -z-10"></div>
      <Link
        href={'/profile'}
        className="z-10 flex items-center justify-between p-1 "
      >
        <h1 className="pl-2 mr-8 font-semibold text-center text-md text-primary-darken">
          {data?.count ?? ''} Ongoing offers
        </h1>
        <div className="p-2 text-2xl text-white rounded-full bg-primary-darken">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-arrow-narrow-right"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M5 12l14 0"></path>
            <path d="M15 16l4 -4"></path>
            <path d="M15 8l4 4"></path>
          </svg>
        </div>
      </Link>
      {/* <CloseIcon
        className="absolute right-0 text-3xl text-white "
        onClick={onOfferwallClose}
      /> */}
    </motion.div>
  );
}

export default StickyBottom;
