import useService from '@/hooks/useService';
import { TrackingData } from '@/model/user';
import { AndroidService } from '@/service/Android';
import { StorageService } from '@/service/StorageService';
import { UserService } from '@/service/User';
import { hexToRgb } from '@/utils';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/nextjs';

export const useInitializeOfferwall = () => {
  const [error, setError] = useState();
  const { call: getProfileId } = useService(UserService.getProfileId);
  const { call: getUser } = useService(UserService.getUser);
  const { call: getCountryCode } = useService(UserService.getCountyCode);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  async function initializeTokens() {
    const appKey = AndroidService.getAppKey();
    try {
      const trackingData = AndroidService.getTrackingData();
      const currentUniqueId = trackingData?.uniqueId;
      const prevUniqueId = StorageService.get('unique_id', true);

      if (
        !!StorageService.get('user_id', true) &&
        !!StorageService.get('profile_id', true) &&
        prevUniqueId == currentUniqueId &&
        StorageService.get('app_key', true) == appKey
      ) {
        setIsLoaded(true);
        setIsUserLoaded(true);
        return;
      }
      StorageService.set('unique_id', currentUniqueId, true);
      StorageService.set('sid', uuidv4(), true); //sid
      StorageService.set('app_key', appKey, true); //app-key
      const { authToken, refreshToken, userId, wallets } = await getUser();
      Sentry.setTag('user-id', userId);
      StorageService.setAccessToken(authToken);
      StorageService.setAuthRefreshToken(refreshToken);
      StorageService.set('user_id', userId, true);
      AndroidService.addAnalyticsUserParam('user_id', userId);
      StorageService.set('wallets', wallets, true);
      setIsUserLoaded(true);
      const formData = new FormData();
      formData.append(
        'file',
        new Blob([
          AndroidService.encrypt(
            JSON.stringify(TrackingData.toHttpObject(trackingData))
          )
        ])
      );
      const { profileId } = await getProfileId(formData);
      StorageService.set('profile_id', profileId, true);
      AndroidService.addAnalyticsUserParam('profile_id', profileId);
      const countryCode = await getCountryCode();
      StorageService.set('country', countryCode, true);

      setIsLoaded(true);
    } catch (err) {
      setIsLoaded(true);
      setError(err);
    }
  }

  useEffect(() => {
    // if (StorageService.get('offerwall_shown', true) === true) {
    //   setIsOfferwallStarted(true);
    //   return;
    // }

    async function initialize() {
      await initializeTokens();
      // setIsOfferwallStarted(true);
      AndroidService.offerWallShowed();
      StorageService.set('offerwall_shown', true, true);
    }

    initialize();
  }, []);

  return { isLoaded, error, isUserLoaded };
};
