import { apiAxiosV1 } from '@/configs/axios';
import { Profile, TrackingData, User } from '@/model/user';
import { StorageService } from '@/service/StorageService';
import { AndroidService } from './Android';
import { API_END_POINT } from '@/configs/config';

export const UserService = {
  async getUser() {
    const url = `offerwall/user/get`;
    const appKey = StorageService.get('app_key', true);
    const trackingData = AndroidService.getTrackingData();
    if (!appKey || !trackingData) return;

    const res = await apiAxiosV1.post(
      url,
      TrackingData.toHttpGetUserObject(trackingData),
      {
        headers: {
          'app-key': appKey
        }
      }
    );
    return User.from(res.data || null);
  },

  async getProfileId(data) {
    const url = `basket/profile/offerwall/init`;
    const res = await apiAxiosV1.post(url, data);
    return Profile.from(res.data || null);
  },
  async getCountyCode() {
    const url = `${API_END_POINT}/v3/geolocate/country_code/get`;
    const res = await apiAxiosV1.get(url);
    return res?.data.code;
  }
};
