import { apiAxiosV1, apiAxiosV2 } from '@/configs/axios';
import { Offer, OfferCount, OfferDetails, OfferV6 } from '@/model/offer';
import { AndroidService } from './Android';
import {
  API_END_POINT,
  API_END_POINT_V2_1,
  API_END_POINT_V2_2
} from '@/configs/config';

// type V2RewardResponse struct {
// 	RewardID    int    `json:"reward_id"`
// 	Label       string `json:"label"`
// 	Description string `json:"description"`
// 	Steps       string `json:"steps"`
// 	Status      string `json:"status"`
// 	Payout      Payout `json:"payout"`

// 	OfferID int `json:"offer_id,omitempty"`
// }

// type V2RetentionRewardResponse struct {
// 	V2RewardResponse
// 	RetentionStatus []V2RetentionStatusResponse `json:"retention_status,omitempty"`
// }

// //swagger:response V2RetentionStatusResponse
// type V2RetentionStatusResponse struct {
// 	Day    int    `json:"day"`
// 	Payout Payout `json:"payout"`
// 	Status string `json:"status"` (CLAIMED, LOCKED, UNLOCKED, EXPIRED)
// }
// /v2.1/offer/offerwall/ongoing/list
// /v2.1/offer/offerwall/details
// /v2.1/offer/offerwall/completed/list
// /v2.1/offer/offerwall/active/list
export const OfferService = {
  async getActiveOffers({ page = 1, tags, sort }) {
    const params = {
      page,
      sort
    };
    if (tags?.length) {
      params.tags = tags.join(',');
    }
    const url = API_END_POINT + `/v6/offer/user/list?list_type=active`;
    const res = await apiAxiosV2.get(url, {
      params
    });
    if (!res?.data?.results) {
      AndroidService.logEvent('offer_404', null);
    }
    const activeOffers = OfferV6.fromAll(res?.data?.results || []);
    if (!activeOffers) return activeOffers;
    return activeOffers.filter(offer => {
      return !(
        AndroidService.isAppInstalled(offer.androidPackageName) &&
        offer.userStatus === 'NIL'
      );
    });
  },
  async getCompletedOffers({ page = 1 }) {
    const params = {
      page
    };
    const url = API_END_POINT + `/v6/offer/user/list?list_type=completed`;
    const res = await apiAxiosV2.get(url, {
      params
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },
  async getExpiredOffers({ page = 1 }) {
    const params = {
      page
    };
    const url = API_END_POINT + `/v6/offer/user/list?list_type=expired`;
    const res = await apiAxiosV2.get(url, {
      params
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },

  async getOngoingOffers({ page = 1 }) {
    const params = {
      page
    };
    const url = API_END_POINT + `/v6/offer/user/list?list_type=ongoing`;
    const res = await apiAxiosV2.get(url, {
      params
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },

  async getOfferDetails(offerId) {
    const url = API_END_POINT + `/v6/offer/user/details?offer_id=${offerId}`;
    const res = await apiAxiosV2.get(url);
    const data = res?.data;
    return OfferDetails.from(data);
  },

  async getOfferCtaUrl({ rewardId, gaId }) {
    const url = `/offer/offerwall/reward/cta?reward_id=${rewardId}&ga_id=${gaId}`;
    const res = await apiAxiosV1.get(url);
    return res?.data || null;
  },
  async redeemRetention(data) {
    const url = `/user_offer/offerwall/retention/redeem`;
    const res = await apiAxiosV1.post(url, data);
    return;
  },

  // count apis
  async getOngoingOffersCount() {
    const url = 'user_offer/offerwall/ongoing/count';
    const res = await apiAxiosV1.get(url);
    return OfferCount.from(res?.data);
  },
  async getCompletedOffersCount() {
    const url = 'user_offer/offerwall/completed/count';
    const res = await apiAxiosV1.get(url);
    return OfferCount.from(res?.data);
  },
  async getExpiredOffersCount() {
    const url = 'user_offer/offerwall/expired/count';
    const res = await apiAxiosV1.get(url);
    return OfferCount.from(res?.data);
  },
  async addVote({ offerId, vote, comment }) {
    const url = 'user_offer/vote/submit';
    const res = await apiAxiosV1.post(url, {
      offer_id: offerId,
      vote_state: vote,
      vote_comment: comment
    });
    return res?.data;
  }
};
