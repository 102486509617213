import { AndroidService } from '@/service/Android';
import Button from '../ui/Button';
import Dialog from '../ui/Dialog';

export default function ToSFallbackDialog({
  isOpen = false,
  onOpenChange = open => {}
}) {
  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title={<div className="text-center">Don’t miss out</div>}
      className="landscape:max-w-sm"
      actions={({ close }) => (
        <div className="px-6 flex flex-col gap-3 justify-center">
          <Button
            color="primary"
            variant="contained"
            className="w-full h-12 font-bold"
            onClick={() => {
              AndroidService.closeOfferWall();
              close();
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            variant="contained"
            className="w-auto px-2 py-0 h-auto leading-none self-center font-semibold"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="text-sm text-center leading-[24px] font-normal text-dimmed/60">
        Do you really want to go back to App?
      </div>
    </Dialog>
  );
}
