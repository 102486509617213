'use client';

import { AndroidService } from '@/service/Android';
import { useRouter } from 'next/navigation';
import React from 'react';
import { twMerge } from 'tailwind-merge/es5';
import Spacer from './ui/Spacer';

export default function ReachUs({ className }) {
  const router = useRouter();

  const onOpenPrivacy = () => {
    AndroidService.openUrlInBrowser('https://www.greedygame.com');
  };

  const openSupport = () => {
    router.push('/help');
  };

  return (
    <div className={twMerge('px-4 py-8 bg-primary/10', className)}>
      <h1 className="mb-5 text-3xl font-bold text-black/40">
        We bring only the Best offers just for you!
      </h1>
      <p className="font-semibold ">
        Facing issues?{' '}
        <span className="text-primary" onClick={openSupport}>
          Reach out to us
        </span>
      </p>
      {/* <Spacer size="large" />
      <div className="flex gap-12">
        <p
          className="text-sm font-semibold text-primary"
          onClick={onOpenPrivacy}
        >
          Privacy Policy
        </p>
        <p
          className="text-sm font-semibold text-primary"
          onClick={onOpenPrivacy}
        >
          Terms & Conditions
        </p>
      </div> */}
    </div>
  );
}
