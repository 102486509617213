'use client';

import { AndroidService } from '@/service/Android';
import { WalletService } from '@/service/Wallet';
import React, { useEffect, useRef, useState } from 'react';
import useService from '@/hooks/useService';
import ClaimRewardIcon from '/public/claim-reward.svg';
import ClaimRewardShadowIcon from '/public/claim-reward-shadow.svg';
import { simplifyNumber } from '@/utils';
import confetti from 'canvas-confetti';
import PoweredBy from './PoweredBy';
import Dialog from '@/components/ui/Dialog';
import { OfferDetails } from '@/model/offer';

/**
 *
 * @param {{offerDetails : OfferDetails}} props
 * @returns
 */

export default function ClaimReward({ offerDetails }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: payout, call: claimReward } = useService(
    WalletService.claimReward
  );
  const prevDetailsRef = useRef(offerDetails);

  useEffect(() => {
    const isOfferCompletedAlready =
      prevDetailsRef.current?.offer?.isCompleted() &&
      offerDetails?.offer?.isCompleted();

    // to avoid unnecessary api calls
    //call only when there is some completed postback or some unclaimed retention
    if (offerDetails) {
      if (
        !(
          offerDetails?.isAnyPostbackCompleted() ||
          offerDetails?.retentionReward?.isUnclaimedAvailable()
        ) ||
        isOfferCompletedAlready ||
        offerDetails?.offer?.isExpired()
      ) {
        return;
      }
    }

    let timerId;
    async function getReward() {
      try {
        const res = await claimReward();
        AndroidService.rewardClaimed(res.amount, res.currency, res.claimToken);
        setIsModalOpen(true);
        timerId = setTimeout(triggerConfetti, 300);
      } catch {}
    }
    getReward();

    prevDetailsRef.current = offerDetails;
    return () => {
      clearTimeout(timerId);
    };
  }, [offerDetails]);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const triggerConfetti = () => {
    confetti({
      particleCount: 150,
      spread: 100,
      origin: { y: 0.6 }
    });
  };

  return (
    <Dialog
      isOpen={isModalOpen}
      onOpenChange={onClose}
      className="claim-reward-overlay"
      closable
    >
      <div className="flex flex-col items-center justify-center h-full gap-11 landscape:gap-4">
        <div className="flex flex-col items-center justify-center flex-1 mt-7 landscape:mt-0">
          <div className="flex flex-col items-center justify-center max-w-sm mb-6 landscape:mb-2">
            <ClaimRewardIcon className="w-[150px]  portrait:claim-bounce landscape:w-[90px] landscape:claim-elevate" />
            <ClaimRewardShadowIcon className="w-[120px] portrait:claim-pulse landscape:w-[70px] landscape:claim-shadow-elevate" />
          </div>
          <h1 className="px-2 mb-2 text-2xl font-bold tracking-tight text-center landscape:text-lg">
            You just received a reward!
          </h1>
          <div className="flex gap-2">
            {AndroidService.getCurrencyIconUrl() && (
              <img
                src={AndroidService.getCurrencyIconUrl()}
                alt="coin"
                className="w-11 h-11 landscape:w-10 landscape:h-10"
              />
            )}
            <div className="flex flex-col">
              <p className="text-xl font-bold landscape:text-lg">
                +{simplifyNumber(payout?.amount || 0)}
              </p>
              <p className="text-xs font-medium text-black/40">
                {AndroidService.getCurrencyName() || 'Gems'} Earned
              </p>
            </div>
          </div>
        </div>
        <div className="mt-auto">
          <PoweredBy
            classes={{
              text: 'text-primary-darken text-[0.6rem] font-bold leading-none',
              image: 'text-primary-darken text-2xl'
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}
