import SkeletonOfferItem from '@/components/skeletons/SkeletonOfferItem';
import clsx from 'clsx';
import React from 'react';

function ActiveListLoader({ className }) {
  return (
    <div
      className={clsx(
        'p-4 gap-3 grid landscape:grid-flow-col-dense landscape:h-[calc(100vh_-_60px)] landscape:w-[107vh] leading-none relative z-0',
        className
      )}
    >
      <SkeletonOfferItem count={4} isLarge />
    </div>
  );
}

export default ActiveListLoader;
