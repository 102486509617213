import {
  PRIVACY_POLICY_PAGE,
  TERMS_CONDITIONS_PAGE
} from '@/constants/routes.const';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import Button from '../ui/Button';
import Dialog from '../ui/Dialog';

export default function TermsOfUsageDialog({
  onAccept = () => {},
  onReject = () => {},
  isOpen = false,
  onOpenChange = open => {}
}) {
  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title="Terms of Usage"
      className="landscape:max-w-2xl portrait:max-h-[60vh]"
      actions={({ close }) => (
        <div className="flex flex-col justify-center gap-3 px-6 landscape:flex-row-reverse landscape:justify-between landscape:px-0 landscape:items-center">
          <Button
            color="primary"
            variant="contained"
            className="w-full h-12 font-bold landscape:w-auto landscape:px-28"
            onClick={() => {
              onAccept();
              close();
            }}
          >
            Accept
          </Button>
          <Button
            color="secondary"
            variant="contained"
            className="self-center w-auto h-auto p-0 font-semibold leading-none"
            onClick={() => {
              onReject();
              close();
            }}
          >
            Reject
          </Button>
        </div>
      )}
    >
      <TermsOfUsageContent />
    </Dialog>
  );
}

function TermsOfUsageContent() {
  const router = useRouter();
  const openPrivacyPolicy = () => {
    router.push(PRIVACY_POLICY_PAGE);
  };
  const openTermsConditions = () => {
    router.push(TERMS_CONDITIONS_PAGE);
  };

  return (
    <div className="text-sm leading-[24px] font-normal text-dimmed/60">
      We hereby inform you that PubScale processes the following personal data
      within the framework of the use of offers:
      <br />
      Installed apps (including the use duration and use history)
      <br />
      The data will be connected to your end device via the device ID and sent,
      encrypted, to our servers. In order for app providers to finance our app
      suggestions, we must send them the device ID for billing purposes.
      <br />
      The processing of the above data is necessary to be able to recommend, via
      system messages, the installation of apps available in Playtime that match
      your interests, and calculate the bonuses acquired as a result of your use
      of the corresponding apps.
      <br />
      Consent By clicking on “ACCEPT”, I give PubScale my consent to process my
      above-mentioned personal data and transmit it to other app providers so
      that I can use Playtime as described.
      <br />I am aware that a profile of interests will be generated using the
      above data, and depending on the types of apps I use, this may contain
      particularly sensitive personal data (e.g. health-related data or data
      about my sexual orientation, and any other data in special categories
      pursuant to Art. 9[1] GDPR).
      <br />
      For that reason my consent also applies explicitly to the processing of
      this data. The links to our Privacy Statement and Terms and Conditions of
      use are available{' '}
      <span className="text-primary" onClick={openPrivacyPolicy}>
        here
      </span>{' '}
      and{' '}
      <span className="text-primary" onClick={openTermsConditions}>
        here
      </span>{' '}
      respectively.
    </div>
  );
}
