import React from 'react';
import SomethingWrongImg from '/public/something-wrong.svg';
import Button from './ui/Button';
import { twMerge } from 'tailwind-merge/es5';
import { AndroidService } from '@/service/Android';

/**
 *
 * @param {Object} props
 * @param {Object} props.classes
 * @param {String} props.classes.container
 * @param {String} props.classes.image
 * @param {String} props.classes.header
 * @param {String} props.classes.btn
 * @param {Function} props.onRefresh
 * @returns
 */

export default function ErrorBoundary({ onRefresh, classes }) {
  return (
    <div
      className={twMerge(
        'py-6 flex flex-col items-center justify-center gap-4',
        classes?.container
      )}
    >
      <SomethingWrongImg className={twMerge('text-[300px]', classes?.image)} />
      <h1
        className={twMerge(
          'text-2xl font-bold leading-none text-center',
          classes?.header
        )}
      >
        Something went wrong
      </h1>
      <p className="text-sm text-center text-black/40">
        This is not you, this is us! <br /> Don’t worry, try again
      </p>
      <Button
        color="primary"
        variant="outlined"
        className={twMerge('py-2 w-44', classes?.btn)}
        onClick={onRefresh}
      >
        Retry
      </Button>
    </div>
  );
}
