export const API_END_POINT = process.env.NEXT_PUBLIC_API_END_POINT;
export const API_END_POINT_V2_1 = API_END_POINT + '/v2.1';
export const API_END_POINT_V2_2 = API_END_POINT + '/v2.2';
export const LOG_API_END_POINT = process.env.NEXT_PUBLIC_LOG_API_END_POINT;

export const ENV =
  process.env.NEXT_PUBLIC_ENV === 'development' ? 'development' : 'production';

export const isProduction = ENV === 'production';

export const isDev = ENV === 'development';
