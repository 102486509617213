'use client';

import { AndroidService } from '@/service/Android';
import React from 'react';

export default function SandboxAlertBar() {
  const isDebug = AndroidService.isDebugEnabled();

  if (!isDebug) return null;
  return (
    <div className="sandboxMode">
      <div className="contentRoot">
        <p semiBold className="text-sm font-semibold content">
          Sandbox Mode
        </p>
      </div>
    </div>
  );
}
