export const ROUTES = {
  HOME: '/',
  PRIVACY_POLICY: '/privacy-policy',
  TERM_CONDITIONS: '/terms-conditions'
};
export const HOME_PAGE = '/';
export const OFFERS_PAGE = '/offers';
export const GET_OFFER_DETAILS_PAGE = id => `/offer/${id}`;
export const PRIVACY_POLICY_PAGE = '/privacy-policy';
export const TERMS_CONDITIONS_PAGE = '/terms-conditions';
