import React from 'react';
import { twMerge } from 'tailwind-merge/es5';
import CloseIconWithBG from '/public/icons/close-with-bg.svg';
import CloseIconDark from '/public/icons/close-with-bg-dark.svg';

const IconHeader = ({ children, className, onClose, darkCloseCta = false }) => {
  // return (
  //   <div
  //     className={twMerge(
  //       'absolute top-[15px] left-0 right-0 px-2 flex justify-between items-center',
  //       className
  //     )}
  //   >
  //     {children}
  //     <CloseIconWithBG
  //       className="ml-auto text-3xl text-white"
  //       onClick={onClose}
  //     />
  //   </div>
  // );
  return (
    <>
      <span
        className={twMerge(
          'top-[15px]  absolute left-0 ml-2 leading-none text-3xl',
          className
        )}
      >
        <span className="text-3xl">{children}</span>
      </span>
      <span
        className={twMerge(
          'top-[15px]  absolute right-0  mr-2 leading-none text-3xl',
          className
        )}
      >
        <span className="inline-flex items-center justify-center">
          {darkCloseCta ? (
            <CloseIconDark className="ml-auto" onClick={onClose} />
          ) : (
            <CloseIconWithBG className="ml-auto text-white" onClick={onClose} />
          )}
        </span>
      </span>
    </>
  );
};

const IconHeaderLeft = ({ children }) => {
  return <>{children}</>;
};

IconHeader.Left = IconHeaderLeft;

export default IconHeader;
