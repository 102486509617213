import { apiAxiosV1 } from '@/configs/axios';
import { API_END_POINT } from '@/configs/config';
import { IncentBanner } from '@/model/Banner';

export const BannerService = {
  async getBanners() {
    const url = `/banner/list`;
    const res = await apiAxiosV1.get(API_END_POINT + '/v1.1' + url);
    return IncentBanner.fromAll(res?.data?.result);
  }
};
