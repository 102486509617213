'use client';
import SuspenseFallback from '@/components/SuspenseFallback';
import FullDialog from '@/components/ui/FullDialog';

export default function Loading() {
  return (
    <FullDialog>
      <SuspenseFallback />
    </FullDialog>
  );
}
