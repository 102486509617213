import { useState, useEffect } from 'react';

const useIntersection = ({
  element,
  rootMargin,
  rootElement,
  initialVisibleState = false
}) => {
  const [isVisible, setState] = useState(initialVisibleState);

  useEffect(() => {
    const ele = element.current;
    const onVisibilityChange = entries => setState(entries[0].isIntersecting);
    const observer = new IntersectionObserver(onVisibilityChange, {
      rootMargin,
      root: rootElement ? rootElement.current : null
    });

    ele && observer.observe(ele);

    return () => ele && observer.unobserve(ele);
  }, []);

  return isVisible;
};

export default useIntersection;
