import Button from '../ui/Button';
import Dialog from '../ui/Dialog';

export default function ToSFallbackDialog({
  onAccept = () => {},
  onBack = () => {},
  isOpen = false,
  onOpenChange = open => {}
}) {
  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title="Don’t miss out"
      className="landscape:max-w-sm"
      actions={({ close }) => (
        <div className="flex flex-col justify-center gap-3 px-6">
          <Button
            color="primary"
            variant="contained"
            className="w-full h-12 font-bold"
            onClick={() => {
              onAccept();
              close();
            }}
          >
            I need rewards
          </Button>
          <Button
            color="secondary"
            variant="contained"
            className="self-center w-auto h-auto px-2 py-0 font-semibold leading-none"
            onClick={() => {
              onBack();
              close();
            }}
          >
            Exit
          </Button>
        </div>
      )}
    >
      <_ToSFallbackContent />
    </Dialog>
  );
}

function _ToSFallbackContent() {
  return (
    <div className="text-sm leading-[24px] font-normal text-dimmed/60">
      Do you really want to go back to App without collecting any rewards?
    </div>
  );
}
