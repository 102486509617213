import { AndroidService } from '@/service/Android';
import { StorageService } from '@/service/StorageService';

export default function useMacros() {
  const user_id = StorageService.get('user_id', true);
  const country = StorageService.get('country', true);
  const app_id = StorageService.get('app_key', true);
  const ga_id = AndroidService.getTrackingData().gaId;

  return {
    user_id,
    country,
    app_id,
    ga_id
  };
}
