'use client';

import HomeHeader from './components/HomeHeader';
import { useInitializeOfferwall } from '@/hooks/useInitializeOfferwall';
import ClaimReward from '@/components/ClaimReward';
import TermsOfUsageAndPermission from './components/TermsOfUsage';
import ActiveListLoader from './components/ActiveListLoader';
import { Suspense } from 'react';

export default function Layout({ children, modal }) {
  const { isLoaded, error } = useInitializeOfferwall();

  if (error) {
    throw new Error('Failed to initialize tokens');
  }

  return (
    <Suspense>
      <div className="landscape:h-screen">
        <div className="landscape:h-[60px]">
          <HomeHeader isOfferwallLoaded={isLoaded} />
        </div>
        <div className="landscape:h-[calc(100vh_-_60px)] landscape:flex landscape:overflow-auto">
          {isLoaded ? <div>{children}</div> : <ActiveListLoader />}
        </div>
      </div>
      {isLoaded && <ClaimReward />}
      <TermsOfUsageAndPermission />
      {modal}
    </Suspense>
  );
}
