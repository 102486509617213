'use client'; // Error components must be Client Components

import ErrorBoundary from '@/components/ErrorBoundary';
import { useEffect } from 'react';
import CloseIcon from '/public/close-icon.svg';
import { AndroidService } from '@/service/Android';

export default function Error({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <div className="flex items-center justify-center h-screen">
      <span className="absolute z-10 right-2 top-5 ">
        <CloseIcon
          onClick={() => {
            AndroidService.closeOfferWall();
          }}
        />
      </span>
      <ErrorBoundary onRefresh={() => reset()} />
    </div>
  );
}
