'use client';

import EnableUsageAccessDialog from '@/components/dialogs/EnableUsageAccessDialog';
import TermsOfUsageDialog from '@/components/dialogs/TermsOfUsageDialog';
import ToSFallbackDialog from '@/components/dialogs/ToSFallbackDialog';
import { DIALOG_CLOSE_TYPES } from '@/components/ui/Dialog';
import { AndroidService } from '@/service/Android';
import { StorageService } from '@/service/StorageService';
import React, { useEffect, useMemo, useState } from 'react';

function TermsOfUsageAndPermission() {
  const [isOpen, setIsOpen] = useState(false);
  const [isFallbackOpen, setIsFallbackOpen] = useState(false);
  function onTermsOfUsageAccept() {
    StorageService.setTermsOfUsageAccepted(true);
  }

  const openTerms = () => {
    setIsOpen(!StorageService.getTermsOfUsageAccepted());
  };

  const onAccept = () => {
    setIsOpen(false);
    onTermsOfUsageAccept();
    AndroidService.logEvent('term_cond_accept', null);
  };

  const onReject = () => {
    setIsFallbackOpen(false);
    AndroidService.closeOfferWall();
    AndroidService.logEvent('term_cond_reject', null);
  };

  useEffect(() => {
    window.onLoaderComplete = openTerms;
    if (window.history?.length > 1) {
      openTerms();
    }
  }, []);

  return (
    <>
      <TermsOfUsageDialog
        isOpen={isOpen}
        onAccept={onAccept}
        onReject={() => {
          setIsFallbackOpen(true);
          setIsOpen(false);
        }}
      />
      <ToSFallbackDialog
        isOpen={isFallbackOpen}
        onAccept={() => {
          // onTermsOfUsageAccept();
          setIsOpen(true);
          setIsFallbackOpen(false);
        }}
        onBack={onReject}
      />
    </>
  );
}

export default TermsOfUsageAndPermission;
