import useService from '@/hooks/useService';
import { useEffect, useState } from 'react';

const usePaginatedService = (service, options) => {
  const { makeFirstCall = false, count = 10, serviceArgs } = options;
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const formator = (newData, oldData) => {
    if (newData?.length < count) {
      setHasMoreData(false);
    }
    if (!oldData) return newData;
    if (!newData?.length) {
      setHasMoreData(false);
      return oldData;
    }
    return [...oldData, ...newData];
  };

  const {
    data,
    isLoaded,
    isLoading,
    call: getData,
    clear: clearData,
    error
  } = useService(service, undefined, formator);

  const fetchData = async (page = 1) => {
    await getData({ page, count, ...(serviceArgs || {}) });
    setCurrentPage(page);
  };

  const loadMoreData = () => {
    fetchData(currentPage + 1);
  };

  const refreshData = async () => {
    clearData();
    await fetchData();
  };

  useEffect(() => {
    if (!makeFirstCall) return;
    fetchData(1);
  }, [makeFirstCall]);

  return {
    data,
    fetchData,
    clearData,
    currentPage,
    hasMoreData,
    isLoaded,
    isLoading,
    refreshData,
    loadMoreData,
    error
  };
};

export default usePaginatedService;
