import { apiAxiosV1, apiAxiosV2 } from '@/configs/axios';
import { Payout } from '@/model/offer';
import { Wallet } from '@/model/wallet';
import { StorageService } from './StorageService';
import { UserEarning } from '@/model/user';

export const WalletService = {
  async getBalance() {
    const url = `/wallet/offerwall/balance/me`;
    const res = await apiAxiosV1.get(url);
    return Wallet.from(res?.data || {});
  },
  async claimReward() {
    const wallets = StorageService.get('wallets', true);
    const walletId = wallets?.length ? wallets[0]?.wid : undefined;
    const url = `/wallet/offerwall/${walletId}/claim`;
    const res = await apiAxiosV1.post(url);
    return Payout.from(res.data || {});
  },
  async getTotalEarned() {
    const wallets = StorageService.get('wallets', true);
    const url = `/wallet/offerwall/${wallets[0]?.wid}/total`;
    const res = await apiAxiosV2.get(url);
    return UserEarning.from(res?.data);
  }
};
