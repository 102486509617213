import React, { useRef } from 'react';
import NoOffersImage from '/public/no-offers.svg';
import Button from './ui/Button';
import StickyBottom from '@/app/(offerwall)/components/StickyBottom';

export default function NoOffers({ onRefresh }) {
  const scrollableRef = useRef(null);
  return (
    <div
      ref={scrollableRef}
      className="py-6 flex flex-col items-center justify-center h-[calc(100vh_-_200px)] gap-3 landscape:h-[calc(100vh_-_60px)] landscape:w-screen"
    >
      <NoOffersImage className="text-[150px]" />
      <h1 className="text-xl font-bold text-center">
        Looks like we are facing high <br /> demand for best offers!
      </h1>
      <p className="text-sm text-center text-black/40">
        Don’t worry, try again after sometime.
      </p>
      <Button
        color="primary"
        variant="outlined"
        className="w-48 py-2"
        onClick={onRefresh}
      >
        Try again
      </Button>
      <StickyBottom scrollContainer={scrollableRef?.current} />
    </div>
  );
}
