import React from 'react';
import Pubscale from '/public/pubscale.svg';
import { twMerge } from 'tailwind-merge/es5';

/**
 *
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.classes - The size of the component.
 * @param {string} props.classes.container - The CSS class for the container element.
 * @param {string} props.classes.image - The CSS class for the container element.
 * @param {string} props.classes.text - The CSS class for the text element.
 * @returns {JSX.Element} A React JSX element representing the component.
 * @example
 * // Example usage of the component
 * // Renders the component with a medium size
 * <Spacer size="medium" />
 */

export default function PoweredBy({ classes }) {
  return (
    <div className={twMerge('flex items-end gap-1', classes?.container)}>
      <p
        className={twMerge(
          'text-xs font-bold leading-3 text-white',
          classes?.text
        )}
      >
        POWERED BY
      </p>
      <Pubscale
        alt="pubscale"
        className={twMerge('text-lg text-white', classes?.image)}
      />
    </div>
  );
}
